<template>
  <div class="form-login">
    <form @submit.prevent="login">
      <base-text-box ref="email"
                     name="email"
                     v-model="email"
                     :rounded="true"
                     :placeholder="$t('forms.formLogin.emailPlaceholder')">
      </base-text-box>
      <base-text-box ref="password"
                     class="password-input"
                     name="password"
                     type="password"
                     v-model="password"
                     :show-password-recovery-link="true"
                     :rounded="true"
                     :placeholder="$t('forms.formLogin.passwordPlaceholder')"
                     @askPasswordRecovery="$emit('askPasswordRecovery')">
      </base-text-box>
      <div class="form-group">
        <base-button class="full-width"
                     type="submit"
                     :loading="loading">
          {{ $t('forms.formLogin.signIn') }}
        </base-button>
      </div>
      <base-alert type="error"
                  :center-text="true"
                  v-if="loginFail">
        {{ $t('forms.formLogin.wrongPasswordOrEmail') }}
      </base-alert>
    </form>

    <div class="social-networks">
      <div class="title">
        <span>
          {{ $t('forms.formLogin.socialNetworks.title') }}
        </span>
      </div>

      <div class="buttons">
        <base-button-icon icon="vkontakte"
                          buttonClass="transparent large"
                          @click="loginViaSocialNetwork('vkontakte')">
        </base-button-icon>
        <base-button-icon icon="facebook"
                          buttonClass="transparent large"
                          @click="loginViaSocialNetwork('facebook')">
        </base-button-icon>
        <base-button-icon icon="google"
                          buttonClass="transparent large"
                          @click="loginViaSocialNetwork('google')">
        </base-button-icon>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    login as loginViaEmail,
    loginViaVkontakte,
    loginViaFacebook,
    loginViaGooglePlus
  } from '@/services/auth'

  export default {
    data() {
      return {
        email: '',
        password: '',
        longLive: true,
        loading: false,
        loginFail: false
      }
    },

    methods: {
      login() {
        this.loading = true
        this.toggleError(false)

        let data = {
          engine: 'email',

          credentials: {
            email:    this.email,
            password: this.password
          },

          long_live: this.longLive
        }

        loginViaEmail(data)
          .then((response) => {
            this.onLoginSuccess(response)
          })
          .catch(() => {
            this.onLoginFail()
          })
      },

      loginViaSocialNetwork(name) {
        this.loading = true
        this.toggleError(false)

        let callbacks = {
          onSuccess: this.onLoginSuccess,
          onFail: this.onLoginFail,
          onClose: () => {
            this.loading = false
          }
        }

        switch (name) {
          case 'vkontakte':
            loginViaVkontakte(callbacks)
            break

          case 'facebook':
            loginViaFacebook(callbacks)
            break

          case 'google':
            loginViaGooglePlus(callbacks)
            break
        }
      },

      onLoginSuccess(response) {
        this.loading = false
        this.toggleError(false)

        this.setCurrentUser(response.user)
        this.setAuthorizationTokens({
          accessToken: response.access_token,
          expireToken: response.expire_token
        })

        window.location.reload()

        // This is made to properly redirect user to the home page when page reloads after logging in
        localStorage.setItem('routeToRedirect', JSON.stringify({ name: 'home' }))
      },

      onLoginFail() {
        this.loading = false
        this.toggleError(true)
      },

      toggleError(errorState) {
        this.loginFail = errorState
        this.$emit('errorStateChange', this.loginFail)
      },

      ...mapActions('auth', [
        'setCurrentUser',
        'setAuthorizationTokens',
      ])
    }
  }
</script>

<style lang="scss" scoped>


  .form-login {
    .social-networks {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 28px;

        span {
          margin: 0 12px;
          font-size: 14px;
          color: #797979;
        }

        &:before,
        &:after {
          content: "";
          flex-grow: 1;
          height: 2px;
          border-bottom: 1px solid #797979;
          opacity: .5;
        }
      }

      .buttons {
        display: flex;
        justify-content: center;

        button:not(:last-child) {
          margin-right: 28px;
        }
      }
    }
  }
</style>
