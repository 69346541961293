<template>
  <div class="login" :class="$mq">
    <div class="panel">
      <div class="heading-accent text-center">
        {{ $t('auth.login.title') }}
      </div>
      <form-login @askPasswordRecovery="goToPasswordRecovery"></form-login>
    </div>
  </div>
</template>

<script>
  import FormLogin from '@/components/Forms/FormLogin.vue'
  import hasBackgroundMixin from '@/services/mixins/hasBackgroundMixin.js'

  export default {
    components: {
      FormLogin
    },

    mixins: [hasBackgroundMixin],

    methods: {
      goToPasswordRecovery() {
        this.$router.push({ name: 'passwordRecovery' })
      }
    }
  }
</script>

<style lang="scss">
  .login {
    max-width: 600px;
    margin: 0 auto;
    padding-top: 20px;

    .panel {
      padding: 20px 20px 20px 30px;
      margin: 0 20px;

      background: #FFFFFF;
      box-shadow: 0 6px 12px 0 rgba(54,76,106,0.20);
      border-radius: 8px;
    }
  }
</style>
